@import './reset.scss';
@import './breakpoints.scss';
@import './helpers.scss';
@import './services.scss';
@import './colors.scss';
@import './form.scss';
@import './spacing.scss';
@import './typography.scss';

body {
  background: white;
  font-size: $base-font-size;
  color: $font-color;
}

.logotype {
  width: 100%;
  max-width: rem-calc(200);
  margin: 0 auto;
  padding-bottom: $spacing-medium;
  display: block;
}

.main_wrapper {
  padding: $spacing-medium;
}

.breadcrumbs {
  padding-bottom: $spacing-medium;
}

.tippy-tooltip.varmber-theme {
  background-color: $accent-color;
  color: white;
}

.tippy-tooltip.varmber-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-tooltip.varmber-theme .tippy-backdrop {
  background-color: $accent-color;
}

.p_row {
  display: flex;
  justify-content: space-between;
  > div {
    width: 30%;
    p {
      text-align: left;
    }
  }
}
