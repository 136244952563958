@import "./colors.scss";
@import "./spacing.scss";
@import "./breakpoints.scss";
@import "./typography.scss";

* {
  outline-color: $primary-active;
}

.label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}

.info-icon,
.error-icon {
  font-family: "zurichlightcondensed";
  position: relative;
  cursor: pointer;
  span {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    min-width: rem-calc(140);
    z-index: 200;
    padding: $spacing-small;
    transition: 0.4s ease all;
    line-height: rem-calc(18);
    font-size: rem-calc(14);
    color: white;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      transform: translateX(-100%) translateY(-50%);
      top: 50%;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
  &:hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
  &.left span {
    &::after {
      right: 50px;
      transform: translateX(100%) translateY(-50%) rotate(180deg);
      -webkit-transform: translateX(100%) translateY(-50%) rotate(180deg);
      -moz-transform: translateX(100%) translateY(-50%) rotate(180deg);
      -ms-transform: translateX(100%) translateY(-50%) rotate(180deg);
      -o-transform: translateX(100%) translateY(-50%) rotate(180deg);
    }
  }
}

.info-icon {
  span {
    background: $accent-color;
    border: darken($accent-color, 2%) rem-calc(1) solid;
    left: $spacing-large;

    &::after {
      border-right: 20px solid $accent-color;
      left: 0;
    }
  }
  &.left span {
    left: auto;
    right: $spacing-large;
    &::after {
      left: auto;
      right: 0;
    }
  }
}

.error-icon {
  span {
    background: $error-color;
    border: darken($error-color, 2%) rem-calc(1) solid;
    right: $spacing-large;

    @include md {
      left: $spacing-large;
    }
    &::after {
      @include mdMax {
        transform: translateX(100%) translateY(-50%);
        border-left: 20px solid $error-color;
      }
      @include md {
        border-right: 20px solid $error-color;
        left: 0;
      }
    }
  }
  img {
    color: $error-color;
  }
}

.btn-primary {
  padding: 0 rem-calc(17);
  height: rem-calc(40);
  background-color: $primary-color;
  font-family: "zurichboldcondensed";
  color: white;
  border: none;
  text-transform: uppercase;
  font-size: rem-calc(13);
  text-decoration: none;
  font-weight: 400;
  transition: 0.2s background-color ease;
  width: 100%;
  letter-spacing: rem-calc(1.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: $primary-active;
  }
  > img {
    margin-right: $spacing-small;
    height: rem-calc(20);
  }
  @include md {
    width: auto;
  }

  &:hover {
    background-color: darken($primary-color, 5%);
    color: white;
  }
  &.tab-buttons {
    opacity: 0.25;
    &:hover {    
      opacity: 0.5;
    }
    &.active {
      opacity: 1;
    }
  }

  &:first-of-type {
    margin-right: 0;
  }
}

label {
  img {
    padding: 0 $spacing-small 0 $spacing-tiny;
  }
}

select,
input {
  background: white;
  border: $border-color solid rem-calc(1);
  border-radius: rem-calc(0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - #{rem-calc(50)});
  padding: $spacing-small 0.5rem;
  @include md {
    width: auto;
  }
}

select {
  background: white;
  border: $border-color solid rem-calc(1);
  border-radius: rem-calc(0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./../icons/select-arrow.svg"); /* add custom arrow */
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  // Same height as inputs and buttons
  height: 2.5rem;
}

input {
  // Same height as button, minus vertical padding, minus vertical border
  height: calc(#{rem-calc(40)} - #{$spacing-small} - #{$spacing-small} - #{rem-calc(4)});
}
.read-only {
  display: flex;
  position: relative;
  align-items: center;
  > input {
    background-color: rgba(0, 0, 0, 0.035);
    border-color: $header-color;
    padding-left: 35px;
  }
  &:before {
    content: url("./../icons/lock.svg");
    position: absolute;
    opacity: 0.7;
    margin-top: rem-calc(2);
    left: rem-calc(5);
  }
}

// input[type='number'] {
//   background-image: url('./../icons/input-number.svg'); /* add custom arrow */
//   background-repeat: no-repeat;
//   background-position-x: right;
//   background-position-y: center;
//   &:hover {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//   }
// }

fieldset {
  label,
  .label {
    &.radio_button {
      padding-top: $spacing-small;
      padding-bottom: $spacing-small;
      padding-left: rem-calc(42);
      padding-right: $spacing-medium;
      font-size: rem-calc(16);
    }
    padding-top: $spacing-medium;
    padding-bottom: $spacing-small;
    display: flex;
    ion-icon {
      padding: 0 rem-calc(6);
      color: $accent-color;
    }
  }
  select,
  input[type="text"],
  input[type="email"] {
    min-width: auto;
    width: 100%;
    @include md {
      width: rem-calc(382);
    }
  }
  textarea {
    width: rem-calc(382);
    height: 60px;
    resize: none;
    background: white;
    border: #d9e0d8 solid 0.0625rem;
    border-radius: 0rem;
    padding: 0.5625rem 0.5rem
  }
}

input,
select {
  font-size: rem-calc(12);
  max-width: calc(100% - #{$spacing-small} - #{$spacing-small});
  padding: rem-calc(9) $spacing-small;
}

.input_flex {
  display: flex;
  flex-direction: column;
}

.input_flex_row {
  display: flex;
  flex-direction: row;
}

//Units after inputs
fieldset {
  width: 100%;
  @include md {
    width: auto;
  }
  div {
    position: relative;
    > div {
      flex-grow: 1;
      @include md {
        flex-grow: inherit;
      }
    }
    @include md {
      width: auto;
    }
  }

  div::after {
    position: relative;
    transition: all 0.05s ease-in-out;
    padding-left: $spacing-tiny;
    @include md {
      padding-right: $spacing-small;
    }
  }
  .concentration {
    padding-left: rem-calc(0);
  }
  .concentration::after {
    content: "%";
  }

  .effect:after {
    content: "kW";
  }
  .pressure:after {
    content: "bar";
  }
  .static_height:after,
  .pipe_length::after {
    content: "m";
  }
  .additional:after,
  .inlet_temperature:after,
  .return_temperature:after {
    content: "°C";
  }
  .volume:after,
  .total_system_volume:after {
    content: "l";
  }
}

.calculate-volume {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;

  .input-total-volume {
    @include mdMax {
      flex-grow: 1;
    }
    input {
      width: 100%;
    }
  }
  input,
  button {
    margin-bottom: $spacing-small;
    @include md {
      margin-bottom: 0;
    }
  }
  .calculate-button-container {
    @include mdMax {
      width: 100%;
    }
    .calculate-button {
      margin-left: $spacing-small;
      @include mdMax {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

//Fluid
.fluid-type {
  flex-grow: 1;
  @include md {
    margin-right: rem-calc(6);
    flex-grow: 0;
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: rem-calc(35);
  margin-bottom: rem-calc(12);
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: rem-calc(24);
  width: rem-calc(24);
  background-color: #eee;
  border-radius: 50%;
  border: solid rem-calc(4) #eee;
}

/* On mouse-over, add a grey background color */
.container {
  input ~ .checkmark {
    transition: 0.2s ease all;
  }
  &:hover {
    input ~ .checkmark {
      background: $primary-color;
      border: $primary-color solid rem-calc(4);
    }
  }
  input:focus ~ .checkmark {
    box-shadow: 0 0 2pt 1pt $primary-active;
  }
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: $primary-color solid rem-calc(4);
}

.container input:focus {
  background-color: blue;
  outline: green 1px solid;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  background: $primary-color;
  top: rem-calc(7);
  left: rem-calc(7);
  width: rem-calc(11);
  height: rem-calc(11);
  border-radius: 50%;
}

.addIconStyle {
  width: rem-calc(20);
  height: rem-calc(20);
  position: relative;
  top: 2px;
}

.iconStyle {
  width: rem-calc(16);
  height: rem-calc(16);
  top: rem-calc(-2);
  position: relative;
}

.expansion-vessel {
  margin-left: 0;
  &.expanded {
    margin-bottom: $spacing-medium;
  }
  @include md {
    margin-left: rem-calc(25);
  }
}

.text-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

.number-input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  @include md {
    flex-grow: initial;
  }
}

// Form icons
.input-icon-info {
  width: rem-calc(15);
  height: rem-calc(15);
}

.input-icon-error {
  width: rem-calc(25);
  height: rem-calc(25);
}

// Error
.invalid-input {
  border-color: $error-color;
  color: $error-color;
}

.select-button-container {
  display: flex;
  > button {
    margin-right: $spacing-small;
  }
}

.form-wrapper {
  width: 700px;
  margin-right: 20px;
  border: #d9e0d8 solid 0.0625rem;
  position: relative;;
  padding-top: 40px;
  > a {
    position: absolute;
    width: 350px;
    box-sizing: border-box;
    top: 0;
    &:nth-of-type(1) {
      left: 0;
    }
    &:nth-of-type(2) {
      right: 0;
    }
  }
}

.email-form-wrapper {
  padding: 20px;
}


.send-as-email {
  margin: 0 0 $spacing-base 0;
  display: flex;
  align-items: flex-end;
  > button {
    margin-left: $spacing-small;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-left: $spacing-large;
    }
  }
}

.send-as-inquiry {
  margin: 0 0 $spacing-base 0;
  @include md {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: rem-calc(382);
    max-width: rem-calc(382);
  }
  > button {
    margin-top: $spacing-small;
    width: fit-content;
  }
}

.fluid-container {
  display: flex;
  align-items: flex-end;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

// Select with info
.select-with-info-container {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    min-width: 200px;
  }
}

// To prevent zoom in for iphone on focus
input[type="text"],
input[type="number"],
textarea,
select {
  font-size: $base-font-size;

  @include md {
    font-size: rem-calc(12);
  }
}
