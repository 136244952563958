@import "./colors.scss";
@import "./breakpoints.scss";
@import "./spacing.scss";

.service_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service_wrapper {
  .service {
    display: flex;
    align-items: center;
    color: $header-color;
    border-bottom: 1px solid $border-color;
    ion-icon {
      font-size: 30px;
    }
    h2 {
      margin: 0;
      padding: rem-calc(48) rem-calc(4);
    }
  }
}

.section-intro {
  padding-top: $spacing-large;
  padding-bottom: $spacing-huge;
}

.r_row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: $spacing-medium;
  @include md {
    flex-direction: row;
  }
  div {
    border-bottom: rem-calc(1) solid $border-color;
    border-right: none;
    flex-grow: 1;
    padding: $spacing-medium $spacing-small;

    @include md {
      border-right: rem-calc(1) solid $border-color;
      border-bottom: none;
      padding: $spacing-medium;
    }

    @include lg {
      padding: $spacing-large;
    }

    &:last-child {
      border: none;
    }
  }
  p {
    text-align: left;
  }
}

#service_1,
#service_2,
#service_3 {
  &:hover {
    cursor: pointer;
  }
}

#service_1_form,
#service_2_form,
#service_3_form {
  display: none;
  &.show {
    display: block;
  }
}

div.volume_help_panel {
  border: $border-color solid rem-calc(1);
  .header {
    display: flex;
    justify-content: space-between;
    > h4 {
      width: 50%;
    }
    > button {
      width: 50%;
    }
  }

  ul.circuit-vol-list {
    li {
      display: flex;
      justify-content: space-between;
      > fieldset {
        width: 50%;
      }
    }
  }

  div.culvert {
    margin: $spacing-medium 0;
    display: inline-block;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 0;
    width: 100%;
    @include md {
      margin-right: $spacing-large;
      width: rem-calc(382);
    }
    fieldset input[type="number"] {
      min-width: auto;
    }
    .culvert_section {
      display: flex;
      justify-content: space-between;
      > fieldset {
        width: 50%;
      }
      select,
      input[type="text"] {
        min-width: auto;
        width: 100%;
        box-sizing: border-box;
        @include md {
          min-width: rem-calc(200);
          max-width: rem-calc(382);
        }
      }
    }
  }
}

.section-calculate-system-volume {
  border: $border-color rem-calc(1) solid;
  margin-bottom: $spacing-medium;
  width: 100%;
  max-width: rem-calc(813);
  .body {
    padding: 0 $spacing-medium $spacing-medium $spacing-medium;
    > div {
      padding-bottom: $spacing-medium;
    }
  }
}

.total_volume_section {
  display: flex;
  flex-direction: column;

  > fieldset {
    width: 100%;
    @include md {
      width: 50%;
    }
  }
  > button {
    width: 20%;
  }
}

#volume_help_btn {
  background-color: rgb(33, 104, 35);
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  border: none;
  background: $primary-light-color;
  border-radius: 50%;
  width: rem-calc(26);
  height: rem-calc(26);
  padding: rem-calc(1) 0 0 0;
  transition: ease 0.2s background;
  &:hover {
    background: darken($primary-light-color, 5%);
  }
  img {
    height: rem-calc(20);
    width: rem-calc(20);
  }
}

.box-header {
  text-align: center;
  padding: $spacing-small;
  background-color: $primary-light-color;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: absolute;
    right: $spacing-small;
    cursor: pointer;
  }
}

.add_circuit_wrapper {
  margin: $spacing-huge 0;
  display: flex;
  flex-direction: column;
  font-family: "zurichlightcondensed";
  @include md {
    flex-direction: row;
  }

  > * {
    @include mdMax {
      margin-bottom: $spacing-base;
    }
  }

  .number-input-outer-container {
    display: flex;
    align-items: center;
  }

  .number-input-container {
    flex-grow: 1;
    .inlet_temperature,
    .return_temperature {
      display: flex;
      align-items: center;
    }
    input {
      flex-grow: 1;
    }
  }

  .new_circuit {
    border: $border-color solid rem-calc(1);
    display: inline-block;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 0;
    width: 100%;
    @include md {
      margin-right: $spacing-large;
      width: rem-calc(382);
    }

    section {
      padding: 0 $spacing-medium $spacing-medium $spacing-medium;
    }
    fieldset input[type="text"] {
      min-width: auto;
    }
  }
  .add_circuit {
    display: flex;
    width: 100%;
    border: none;
    margin-bottom: $spacing-medium;
    justify-content: center;
    align-items: center;
    padding-top: $spacing-medium;
    transition: 0.2s ease background;
    @include md {
      padding-top: 0;
      margin-bottom: 0;
      width: rem-calc(376);
      border: $border-color dashed rem-calc(3);
    }
    .button_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        padding-top: $spacing-small;
        color: $primary-color;
        transition: 0.2s ease color;
      }

      .add_circuit_button {
        transition: ease 0.2s background;
        cursor: pointer;
        margin-bottom: $spacing-small;
      }

      &:hover {
        cursor: pointer;
        span {
          color: darken($primary-active, 5%);
        }
        .add_circuit_button {
          background: darken($primary-light-color, 5%);
        }
      }
    }
    button {
      background-color: $primary-light-color;
      border-radius: 50%;
      width: rem-calc(42);
      height: rem-calc(42);
      border: none;
      &:hover {
        ion-icon {
          transform: scale(1.2);
          color: $primary-active;
        }
      }
      ion-icon {
        font-size: rem-calc(24);
        position: relative;
        top: rem-calc(2);
        color: $primary-color;
      }
    }
  }
  select {
    width: 100%;
  }
}

.calculating_data {
  padding: $spacing-base $spacing-base;
  @include md {
    padding: $spacing-medium;
    max-width: rem-calc(600);
  }
  border: $border-color solid rem-calc(1);

  overflow-x: auto;
  @include md {
    overflow: auto;
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 rem-calc(15);

    th {
      text-align: left;
      padding-right: $spacing-base;
      border-bottom: black solid rem-calc(1);
    }
  }
}

.results {
  border: $border-color rem-calc(1) solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: rem-calc(340);
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: rem-calc(340);
    height: 100%;
    text-align: center;
    @include mdMax {
      padding: $spacing-base;
    }
    ion-icon {
      font-size: rem-calc(48);
    }
    span {
      padding-top: $spacing-medium;
    }
  }
}
.no-results {
  min-height: rem-calc(210);
  > div {
    min-height: rem-calc(210);
  }
}

// IE can't handle flex with only min-height
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .no-results {
    height: rem-calc(340);
  }
}

.expansion-list-header {
  h2 {
    margin-top: 0;
  }
}

.expansion-bar {
  display: flex;
  align-items: flex-end;
  padding: $spacing-medium;
  border-top: 2px solid $border-color;
  width: 100%;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  text-transform: uppercase;
  letter-spacing: rem-calc(0.5);
  cursor: pointer;
  transition: 0.2s ease background-color;
  &.active,
  &:hover {
    background-color: $primary-light-color;
  }
  h3,
  h5 {
    margin: 0;
    padding-left: $spacing-small;
    font-weight: 400;
  }
}
