$primary-active: #5e7e69;
$border-color: #d9e0d8;
$border-dark-color: #2a2c2a;
$primary-light-color: #eff4ef;
$primary-color: #719685;
$accent-color: #f3960b;
$error-color: #F41907;
$font-invert: #fff;
$font-color: #707070;
$header-color: #222222;
$font-disabled: #9a9a9d;
