@import './helpers.scss';
@import './colors.scss';
@import './spacing.scss';

@font-face {
  font-family: 'zurichblack';
  src: url('../fonts/zurichbt-black-webfont.woff2') format('woff2'),
       url('../fonts/zurichbt-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zurichboldcondensed';
  src: url('../fonts/zurichbt-boldcondensed-webfont.woff2') format('woff2'),
       url('../fonts/zurichbt-boldcondensed-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zurichlightcondensed';
  src: url('../fonts/zurichbt-lightcondensed-webfont.woff2') format('woff2'),
       url('../fonts/zurichbt-lightcondensed-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Zurich Win95BT';
  src: url('../fonts/ZurichBT-Roman.woff2') format('woff2'),
      url('../fonts/ZurichBT-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Zurich Win95BT', sans-serif;
}

h1, h2, h3, h5, h6, label {
  font-family: 'zurichlightcondensed';
}

h4 {
  font-weight: bold;
}

$base-font-size: rem-calc(16);

h2 {
  font-size: rem-calc(22);
  line-height: rem-calc(40);
  margin-top: $spacing-huge;
  margin-bottom: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.01em;
}

h3 {
  font-size: rem-calc(18);
  margin-bottom: 0.75rem;
  font-weight: 400;
}

a {
  color: $primary-color;
  &:hover {
    color: $primary-active;
  }
  ion-icon {
    top: 2px;
    position: relative;
    padding-right: $spacing-tiny;
  }
}

p {
  max-width: rem-calc(600);
  line-height: rem-calc(28);
  margin-bottom: $spacing-medium;
  font-size: rem-calc(16);
}
