@import "./colors.scss";
@import "./spacing.scss";

.table-container {
  border: $border-color solid rem-calc(1);
  padding: $spacing-medium;
  margin-bottom: $spacing-base;
  overflow: auto;
  margin-right: $spacing-base;
  .alternative-button {
    text-align: left;
    background: transparent;
    border: none;
    padding: 0;
    &:focus {
      outline: none;
      color: $primary-color;
      h3 {
        color: $primary-color;
      }
    }
    h3 {
      margin-bottom: 0;
    }
  }
}
.clickable {
  cursor: pointer;
  &:hover {
    border-color: $primary-color;
    color: $primary-color;
    h3 {
      color: $primary-color;
    }
  }

  button {
    cursor: pointer;
  }
}
.alternative {
  overflow-x: auto;
}

.alternative-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 1rem;
  thead {
    background-color: $primary-color;
    color: $font-invert;
    font-family: "zurichlightcondensed";
    border-top: rem-calc(12) solid $primary-color;
    border-bottom: rem-calc(12) solid $primary-color;
    > tr {
      th {
        padding-left: $spacing-base;
        padding-right: $spacing-base;
        text-align: left;
        height: 3rem;
        vertical-align: middle;
      }
    }
  }
  tbody {
    > tr {
      &:last-child {
        border-bottom: none;
      }
      > td {
        vertical-align: middle;
        padding-left: $spacing-base;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .articleNumber {
    white-space: nowrap;
  }
  .description {
    word-break: break-word;
    max-width: rem-calc(280);
    min-width: rem-calc(210);
  }
  img {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    width: rem-calc(100);
    height: auto;
    max-height: rem-calc(200);
  }
}

.addons {
  margin-top: $spacing-large;
  margin-left: rem-calc(150);
  h3 {
    margin-bottom: 0;
    font-family: "zurichboldcondensed";
  }
  h4 {
    margin-bottom: $spacing-small;
  }
}
