@charset "UTF-8";
@font-face {
  font-family: "zurichblack";
  src: url("../fonts/zurichbt-black-webfont.woff2") format("woff2"), url("../fonts/zurichbt-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichboldcondensed";
  src: url("../fonts/zurichbt-boldcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-boldcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichlightcondensed";
  src: url("../fonts/zurichbt-lightcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-lightcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zurich Win95BT";
  src: url("../fonts/ZurichBT-Roman.woff2") format("woff2"), url("../fonts/ZurichBT-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Zurich Win95BT", sans-serif;
}

h1, h2, h3, h5, h6, label {
  font-family: "zurichlightcondensed";
}

h4 {
  font-weight: bold;
}

h2 {
  font-size: 1.375rem;
  line-height: 2.5rem;
  margin-top: 3.75rem;
  margin-bottom: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.01em;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
}

a {
  color: #719685;
}
a:hover {
  color: #5e7e69;
}
a ion-icon {
  top: 2px;
  position: relative;
  padding-right: 0.25rem;
}

p {
  max-width: 37.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

* {
  outline-color: #5e7e69;
}

.label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}

.info-icon,
.error-icon {
  font-family: "zurichlightcondensed";
  position: relative;
  cursor: pointer;
}
.info-icon span,
.error-icon span {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  min-width: 8.75rem;
  z-index: 200;
  padding: 0.5rem;
  transition: 0.4s ease all;
  line-height: 1.125rem;
  font-size: 0.875rem;
  color: white;
}
.info-icon span::after,
.error-icon span::after {
  content: "";
  position: absolute;
  right: 0;
  transform: translateX(-100%) translateY(-50%);
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.info-icon:hover span,
.error-icon:hover span {
  visibility: visible;
  opacity: 1;
}
.info-icon.left span::after,
.error-icon.left span::after {
  right: 50px;
  transform: translateX(100%) translateY(-50%) rotate(180deg);
  -webkit-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -moz-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -ms-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -o-transform: translateX(100%) translateY(-50%) rotate(180deg);
}

.info-icon span {
  background: #f3960b;
  border: #e9900b 0.0625rem solid;
  left: 3rem;
}
.info-icon span::after {
  border-right: 20px solid #f3960b;
  left: 0;
}
.info-icon.left span {
  left: auto;
  right: 3rem;
}
.info-icon.left span::after {
  left: auto;
  right: 0;
}

.error-icon span {
  background: #F41907;
  border: #ea1807 0.0625rem solid;
  right: 3rem;
}
@media (min-width: 768px) {
  .error-icon span {
    left: 3rem;
  }
}
@media (max-width: 768px) {
  .error-icon span::after {
    transform: translateX(100%) translateY(-50%);
    border-left: 20px solid #F41907;
  }
}
@media (min-width: 768px) {
  .error-icon span::after {
    border-right: 20px solid #F41907;
    left: 0;
  }
}
.error-icon img {
  color: #F41907;
}

.btn-primary {
  padding: 0 1.0625rem;
  height: 2.5rem;
  background-color: #719685;
  font-family: "zurichboldcondensed";
  color: white;
  border: none;
  text-transform: uppercase;
  font-size: 0.8125rem;
  text-decoration: none;
  font-weight: 400;
  transition: 0.2s background-color ease;
  width: 100%;
  letter-spacing: 0.06875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-primary.active {
  background-color: #5e7e69;
}
.btn-primary > img {
  margin-right: 0.5rem;
  height: 1.25rem;
}
@media (min-width: 768px) {
  .btn-primary {
    width: auto;
  }
}
.btn-primary:hover {
  background-color: #658978;
  color: white;
}
.btn-primary.tab-buttons {
  opacity: 0.25;
}
.btn-primary.tab-buttons:hover {
  opacity: 0.5;
}
.btn-primary.tab-buttons.active {
  opacity: 1;
}
.btn-primary:first-of-type {
  margin-right: 0;
}

label img {
  padding: 0 0.5rem 0 0.25rem;
}

select,
input {
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - 3.125rem);
  padding: 0.5rem 0.5rem;
}
@media (min-width: 768px) {
  select,
  input {
    width: auto;
  }
}

select {
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./../icons/select-arrow.svg"); /* add custom arrow */
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  height: 2.5rem;
}

input {
  height: calc(2.5rem - 0.5rem - 0.5rem - 0.25rem);
}

.read-only {
  display: flex;
  position: relative;
  align-items: center;
}
.read-only > input {
  background-color: rgba(0, 0, 0, 0.035);
  border-color: #222222;
  padding-left: 35px;
}
.read-only:before {
  content: url("./../icons/lock.svg");
  position: absolute;
  opacity: 0.7;
  margin-top: 0.125rem;
  left: 0.3125rem;
}

fieldset label,
fieldset .label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}
fieldset label.radio_button,
fieldset .label.radio_button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.625rem;
  padding-right: 1.5rem;
  font-size: 1rem;
}
fieldset label ion-icon,
fieldset .label ion-icon {
  padding: 0 0.375rem;
  color: #f3960b;
}
fieldset select,
fieldset input[type=text],
fieldset input[type=email] {
  min-width: auto;
  width: 100%;
}
@media (min-width: 768px) {
  fieldset select,
  fieldset input[type=text],
  fieldset input[type=email] {
    width: 23.875rem;
  }
}
fieldset textarea {
  width: 23.875rem;
  height: 60px;
  resize: none;
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  padding: 0.5625rem 0.5rem;
}

input,
select {
  font-size: 0.75rem;
  max-width: calc(100% - 0.5rem - 0.5rem);
  padding: 0.5625rem 0.5rem;
}

.input_flex {
  display: flex;
  flex-direction: column;
}

.input_flex_row {
  display: flex;
  flex-direction: row;
}

fieldset {
  width: 100%;
}
@media (min-width: 768px) {
  fieldset {
    width: auto;
  }
}
fieldset div {
  position: relative;
}
fieldset div > div {
  flex-grow: 1;
}
@media (min-width: 768px) {
  fieldset div > div {
    flex-grow: inherit;
  }
}
@media (min-width: 768px) {
  fieldset div {
    width: auto;
  }
}
fieldset div::after {
  position: relative;
  transition: all 0.05s ease-in-out;
  padding-left: 0.25rem;
}
@media (min-width: 768px) {
  fieldset div::after {
    padding-right: 0.5rem;
  }
}
fieldset .concentration {
  padding-left: 0rem;
}
fieldset .concentration::after {
  content: "%";
}
fieldset .effect:after {
  content: "kW";
}
fieldset .pressure:after {
  content: "bar";
}
fieldset .static_height:after,
fieldset .pipe_length::after {
  content: "m";
}
fieldset .additional:after,
fieldset .inlet_temperature:after,
fieldset .return_temperature:after {
  content: "°C";
}
fieldset .volume:after,
fieldset .total_system_volume:after {
  content: "l";
}

.calculate-volume {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 768px) {
  .calculate-volume .input-total-volume {
    flex-grow: 1;
  }
}
.calculate-volume .input-total-volume input {
  width: 100%;
}
.calculate-volume input,
.calculate-volume button {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .calculate-volume input,
  .calculate-volume button {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .calculate-volume .calculate-button-container {
    width: 100%;
  }
}
.calculate-volume .calculate-button-container .calculate-button {
  margin-left: 0.5rem;
}
@media (max-width: 768px) {
  .calculate-volume .calculate-button-container .calculate-button {
    margin-left: 0;
    width: 100%;
  }
}

.fluid-type {
  flex-grow: 1;
}
@media (min-width: 768px) {
  .fluid-type {
    margin-right: 0.375rem;
    flex-grow: 0;
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #eee;
  border-radius: 50%;
  border: solid 0.25rem #eee;
}

/* On mouse-over, add a grey background color */
.container input ~ .checkmark {
  transition: 0.2s ease all;
}
.container:hover input ~ .checkmark {
  background: #719685;
  border: #719685 solid 0.25rem;
}
.container input:focus ~ .checkmark {
  box-shadow: 0 0 2pt 1pt #5e7e69;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: #719685 solid 0.25rem;
}

.container input:focus {
  background-color: blue;
  outline: green 1px solid;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  background: #719685;
  top: 0.4375rem;
  left: 0.4375rem;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
}

.addIconStyle {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  top: 2px;
}

.iconStyle {
  width: 1rem;
  height: 1rem;
  top: -0.125rem;
  position: relative;
}

.expansion-vessel {
  margin-left: 0;
}
.expansion-vessel.expanded {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .expansion-vessel {
    margin-left: 1.5625rem;
  }
}

.text-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

.number-input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .number-input-container {
    flex-grow: initial;
  }
}

.input-icon-info {
  width: 0.9375rem;
  height: 0.9375rem;
}

.input-icon-error {
  width: 1.5625rem;
  height: 1.5625rem;
}

.invalid-input {
  border-color: #F41907;
  color: #F41907;
}

.select-button-container {
  display: flex;
}
.select-button-container > button {
  margin-right: 0.5rem;
}

.form-wrapper {
  width: 700px;
  margin-right: 20px;
  border: #d9e0d8 solid 0.0625rem;
  position: relative;
  padding-top: 40px;
}
.form-wrapper > a {
  position: absolute;
  width: 350px;
  box-sizing: border-box;
  top: 0;
}
.form-wrapper > a:nth-of-type(1) {
  left: 0;
}
.form-wrapper > a:nth-of-type(2) {
  right: 0;
}

.email-form-wrapper {
  padding: 20px;
}

.send-as-email {
  margin: 0 0 1rem 0;
  display: flex;
  align-items: flex-end;
}
.send-as-email > button {
  margin-left: 0.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send-as-email > button {
    margin-left: 3rem;
  }
}

.send-as-inquiry {
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .send-as-inquiry {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 23.875rem;
    max-width: 23.875rem;
  }
}
.send-as-inquiry > button {
  margin-top: 0.5rem;
  width: fit-content;
}

.fluid-container {
  display: flex;
  align-items: flex-end;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

.select-with-info-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .select-with-info-container {
    min-width: 200px;
  }
}

input[type=text],
input[type=number],
textarea,
select {
  font-size: 1rem;
}
@media (min-width: 768px) {
  input[type=text],
  input[type=number],
  textarea,
  select {
    font-size: 0.75rem;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.service_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_wrapper .service {
  display: flex;
  align-items: center;
  color: #222222;
  border-bottom: 1px solid #d9e0d8;
}
.service_wrapper .service ion-icon {
  font-size: 30px;
}
.service_wrapper .service h2 {
  margin: 0;
  padding: 3rem 0.25rem;
}

.section-intro {
  padding-top: 3rem;
  padding-bottom: 3.75rem;
}

.r_row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1.5rem;
}
@media (min-width: 768px) {
  .r_row {
    flex-direction: row;
  }
}
.r_row div {
  border-bottom: 0.0625rem solid #d9e0d8;
  border-right: none;
  flex-grow: 1;
  padding: 1.5rem 0.5rem;
}
@media (min-width: 768px) {
  .r_row div {
    border-right: 0.0625rem solid #d9e0d8;
    border-bottom: none;
    padding: 1.5rem;
  }
}
@media (min-width: 992px) {
  .r_row div {
    padding: 3rem;
  }
}
.r_row div:last-child {
  border: none;
}
.r_row p {
  text-align: left;
}

#service_1:hover,
#service_2:hover,
#service_3:hover {
  cursor: pointer;
}

#service_1_form,
#service_2_form,
#service_3_form {
  display: none;
}
#service_1_form.show,
#service_2_form.show,
#service_3_form.show {
  display: block;
}

div.volume_help_panel {
  border: #d9e0d8 solid 0.0625rem;
}
div.volume_help_panel .header {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel .header > h4 {
  width: 50%;
}
div.volume_help_panel .header > button {
  width: 50%;
}
div.volume_help_panel ul.circuit-vol-list li {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel ul.circuit-vol-list li > fieldset {
  width: 50%;
}
div.volume_help_panel div.culvert {
  margin: 1.5rem 0;
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  div.volume_help_panel div.culvert {
    margin-right: 3rem;
    width: 23.875rem;
  }
}
div.volume_help_panel div.culvert fieldset input[type=number] {
  min-width: auto;
}
div.volume_help_panel div.culvert .culvert_section {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel div.culvert .culvert_section > fieldset {
  width: 50%;
}
div.volume_help_panel div.culvert .culvert_section select,
div.volume_help_panel div.culvert .culvert_section input[type=text] {
  min-width: auto;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  div.volume_help_panel div.culvert .culvert_section select,
  div.volume_help_panel div.culvert .culvert_section input[type=text] {
    min-width: 12.5rem;
    max-width: 23.875rem;
  }
}

.section-calculate-system-volume {
  border: #d9e0d8 0.0625rem solid;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 50.8125rem;
}
.section-calculate-system-volume .body {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.section-calculate-system-volume .body > div {
  padding-bottom: 1.5rem;
}

.total_volume_section {
  display: flex;
  flex-direction: column;
}
.total_volume_section > fieldset {
  width: 100%;
}
@media (min-width: 768px) {
  .total_volume_section > fieldset {
    width: 50%;
  }
}
.total_volume_section > button {
  width: 20%;
}

#volume_help_btn {
  background-color: rgb(33, 104, 35);
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  border: none;
  background: #eff4ef;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.0625rem 0 0 0;
  transition: ease 0.2s background;
}
.close-button:hover {
  background: #e0eae0;
}
.close-button img {
  height: 1.25rem;
  width: 1.25rem;
}

.box-header {
  text-align: center;
  padding: 0.5rem;
  background-color: #eff4ef;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-header button {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}

.add_circuit_wrapper {
  margin: 3.75rem 0;
  display: flex;
  flex-direction: column;
  font-family: "zurichlightcondensed";
}
@media (min-width: 768px) {
  .add_circuit_wrapper {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .add_circuit_wrapper > * {
    margin-bottom: 1rem;
  }
}
.add_circuit_wrapper .number-input-outer-container {
  display: flex;
  align-items: center;
}
.add_circuit_wrapper .number-input-container {
  flex-grow: 1;
}
.add_circuit_wrapper .number-input-container .inlet_temperature,
.add_circuit_wrapper .number-input-container .return_temperature {
  display: flex;
  align-items: center;
}
.add_circuit_wrapper .number-input-container input {
  flex-grow: 1;
}
.add_circuit_wrapper .new_circuit {
  border: #d9e0d8 solid 0.0625rem;
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .add_circuit_wrapper .new_circuit {
    margin-right: 3rem;
    width: 23.875rem;
  }
}
.add_circuit_wrapper .new_circuit section {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.add_circuit_wrapper .new_circuit fieldset input[type=text] {
  min-width: auto;
}
.add_circuit_wrapper .add_circuit {
  display: flex;
  width: 100%;
  border: none;
  margin-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  transition: 0.2s ease background;
}
@media (min-width: 768px) {
  .add_circuit_wrapper .add_circuit {
    padding-top: 0;
    margin-bottom: 0;
    width: 23.5rem;
    border: #d9e0d8 dashed 0.1875rem;
  }
}
.add_circuit_wrapper .add_circuit .button_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_circuit_wrapper .add_circuit .button_wrapper span {
  padding-top: 0.5rem;
  color: #719685;
  transition: 0.2s ease color;
}
.add_circuit_wrapper .add_circuit .button_wrapper .add_circuit_button {
  transition: ease 0.2s background;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover {
  cursor: pointer;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover span {
  color: #536f5d;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover .add_circuit_button {
  background: #e0eae0;
}
.add_circuit_wrapper .add_circuit button {
  background-color: #eff4ef;
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  border: none;
}
.add_circuit_wrapper .add_circuit button:hover ion-icon {
  transform: scale(1.2);
  color: #5e7e69;
}
.add_circuit_wrapper .add_circuit button ion-icon {
  font-size: 1.5rem;
  position: relative;
  top: 0.125rem;
  color: #719685;
}
.add_circuit_wrapper select {
  width: 100%;
}

.calculating_data {
  padding: 1rem 1rem;
  border: #d9e0d8 solid 0.0625rem;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .calculating_data {
    padding: 1.5rem;
    max-width: 37.5rem;
  }
}
@media (min-width: 768px) {
  .calculating_data {
    overflow: auto;
  }
}
.calculating_data table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.9375rem;
}
.calculating_data table th {
  text-align: left;
  padding-right: 1rem;
  border-bottom: black solid 0.0625rem;
}

.results {
  border: #d9e0d8 0.0625rem solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 21.25rem;
}
.results div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 21.25rem;
  height: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .results div {
    padding: 1rem;
  }
}
.results div ion-icon {
  font-size: 3rem;
}
.results div span {
  padding-top: 1.5rem;
}

.no-results {
  min-height: 13.125rem;
}
.no-results > div {
  min-height: 13.125rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .no-results {
    height: 21.25rem;
  }
}
.expansion-list-header h2 {
  margin-top: 0;
}

.expansion-bar {
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;
  border-top: 2px solid #d9e0d8;
  width: 100%;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  cursor: pointer;
  transition: 0.2s ease background-color;
}
.expansion-bar.active, .expansion-bar:hover {
  background-color: #eff4ef;
}
.expansion-bar h3,
.expansion-bar h5 {
  margin: 0;
  padding-left: 0.5rem;
  font-weight: 400;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.service_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service_wrapper .service {
  display: flex;
  align-items: center;
  color: #222222;
  border-bottom: 1px solid #d9e0d8;
}
.service_wrapper .service ion-icon {
  font-size: 30px;
}
.service_wrapper .service h2 {
  margin: 0;
  padding: 3rem 0.25rem;
}

.section-intro {
  padding-top: 3rem;
  padding-bottom: 3.75rem;
}

.r_row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 1.5rem;
}
@media (min-width: 768px) {
  .r_row {
    flex-direction: row;
  }
}
.r_row div {
  border-bottom: 0.0625rem solid #d9e0d8;
  border-right: none;
  flex-grow: 1;
  padding: 1.5rem 0.5rem;
}
@media (min-width: 768px) {
  .r_row div {
    border-right: 0.0625rem solid #d9e0d8;
    border-bottom: none;
    padding: 1.5rem;
  }
}
@media (min-width: 992px) {
  .r_row div {
    padding: 3rem;
  }
}
.r_row div:last-child {
  border: none;
}
.r_row p {
  text-align: left;
}

#service_1:hover,
#service_2:hover,
#service_3:hover {
  cursor: pointer;
}

#service_1_form,
#service_2_form,
#service_3_form {
  display: none;
}
#service_1_form.show,
#service_2_form.show,
#service_3_form.show {
  display: block;
}

div.volume_help_panel {
  border: #d9e0d8 solid 0.0625rem;
}
div.volume_help_panel .header {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel .header > h4 {
  width: 50%;
}
div.volume_help_panel .header > button {
  width: 50%;
}
div.volume_help_panel ul.circuit-vol-list li {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel ul.circuit-vol-list li > fieldset {
  width: 50%;
}
div.volume_help_panel div.culvert {
  margin: 1.5rem 0;
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  div.volume_help_panel div.culvert {
    margin-right: 3rem;
    width: 23.875rem;
  }
}
div.volume_help_panel div.culvert fieldset input[type=number] {
  min-width: auto;
}
div.volume_help_panel div.culvert .culvert_section {
  display: flex;
  justify-content: space-between;
}
div.volume_help_panel div.culvert .culvert_section > fieldset {
  width: 50%;
}
div.volume_help_panel div.culvert .culvert_section select,
div.volume_help_panel div.culvert .culvert_section input[type=text] {
  min-width: auto;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  div.volume_help_panel div.culvert .culvert_section select,
  div.volume_help_panel div.culvert .culvert_section input[type=text] {
    min-width: 12.5rem;
    max-width: 23.875rem;
  }
}

.section-calculate-system-volume {
  border: #d9e0d8 0.0625rem solid;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 50.8125rem;
}
.section-calculate-system-volume .body {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.section-calculate-system-volume .body > div {
  padding-bottom: 1.5rem;
}

.total_volume_section {
  display: flex;
  flex-direction: column;
}
.total_volume_section > fieldset {
  width: 100%;
}
@media (min-width: 768px) {
  .total_volume_section > fieldset {
    width: 50%;
  }
}
.total_volume_section > button {
  width: 20%;
}

#volume_help_btn {
  background-color: rgb(33, 104, 35);
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  border: none;
  background: #eff4ef;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.0625rem 0 0 0;
  transition: ease 0.2s background;
}
.close-button:hover {
  background: #e0eae0;
}
.close-button img {
  height: 1.25rem;
  width: 1.25rem;
}

.box-header {
  text-align: center;
  padding: 0.5rem;
  background-color: #eff4ef;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-header button {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}

.add_circuit_wrapper {
  margin: 3.75rem 0;
  display: flex;
  flex-direction: column;
  font-family: "zurichlightcondensed";
}
@media (min-width: 768px) {
  .add_circuit_wrapper {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .add_circuit_wrapper > * {
    margin-bottom: 1rem;
  }
}
.add_circuit_wrapper .number-input-outer-container {
  display: flex;
  align-items: center;
}
.add_circuit_wrapper .number-input-container {
  flex-grow: 1;
}
.add_circuit_wrapper .number-input-container .inlet_temperature,
.add_circuit_wrapper .number-input-container .return_temperature {
  display: flex;
  align-items: center;
}
.add_circuit_wrapper .number-input-container input {
  flex-grow: 1;
}
.add_circuit_wrapper .new_circuit {
  border: #d9e0d8 solid 0.0625rem;
  display: inline-block;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .add_circuit_wrapper .new_circuit {
    margin-right: 3rem;
    width: 23.875rem;
  }
}
.add_circuit_wrapper .new_circuit section {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.add_circuit_wrapper .new_circuit fieldset input[type=text] {
  min-width: auto;
}
.add_circuit_wrapper .add_circuit {
  display: flex;
  width: 100%;
  border: none;
  margin-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  transition: 0.2s ease background;
}
@media (min-width: 768px) {
  .add_circuit_wrapper .add_circuit {
    padding-top: 0;
    margin-bottom: 0;
    width: 23.5rem;
    border: #d9e0d8 dashed 0.1875rem;
  }
}
.add_circuit_wrapper .add_circuit .button_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_circuit_wrapper .add_circuit .button_wrapper span {
  padding-top: 0.5rem;
  color: #719685;
  transition: 0.2s ease color;
}
.add_circuit_wrapper .add_circuit .button_wrapper .add_circuit_button {
  transition: ease 0.2s background;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover {
  cursor: pointer;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover span {
  color: #536f5d;
}
.add_circuit_wrapper .add_circuit .button_wrapper:hover .add_circuit_button {
  background: #e0eae0;
}
.add_circuit_wrapper .add_circuit button {
  background-color: #eff4ef;
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  border: none;
}
.add_circuit_wrapper .add_circuit button:hover ion-icon {
  transform: scale(1.2);
  color: #5e7e69;
}
.add_circuit_wrapper .add_circuit button ion-icon {
  font-size: 1.5rem;
  position: relative;
  top: 0.125rem;
  color: #719685;
}
.add_circuit_wrapper select {
  width: 100%;
}

.calculating_data {
  padding: 1rem 1rem;
  border: #d9e0d8 solid 0.0625rem;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .calculating_data {
    padding: 1.5rem;
    max-width: 37.5rem;
  }
}
@media (min-width: 768px) {
  .calculating_data {
    overflow: auto;
  }
}
.calculating_data table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.9375rem;
}
.calculating_data table th {
  text-align: left;
  padding-right: 1rem;
  border-bottom: black solid 0.0625rem;
}

.results {
  border: #d9e0d8 0.0625rem solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 21.25rem;
}
.results div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 21.25rem;
  height: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .results div {
    padding: 1rem;
  }
}
.results div ion-icon {
  font-size: 3rem;
}
.results div span {
  padding-top: 1.5rem;
}

.no-results {
  min-height: 13.125rem;
}
.no-results > div {
  min-height: 13.125rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .no-results {
    height: 21.25rem;
  }
}
.expansion-list-header h2 {
  margin-top: 0;
}

.expansion-bar {
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;
  border-top: 2px solid #d9e0d8;
  width: 100%;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  cursor: pointer;
  transition: 0.2s ease background-color;
}
.expansion-bar.active, .expansion-bar:hover {
  background-color: #eff4ef;
}
.expansion-bar h3,
.expansion-bar h5 {
  margin: 0;
  padding-left: 0.5rem;
  font-weight: 400;
}

@font-face {
  font-family: "zurichblack";
  src: url("../fonts/zurichbt-black-webfont.woff2") format("woff2"), url("../fonts/zurichbt-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichboldcondensed";
  src: url("../fonts/zurichbt-boldcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-boldcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichlightcondensed";
  src: url("../fonts/zurichbt-lightcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-lightcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zurich Win95BT";
  src: url("../fonts/ZurichBT-Roman.woff2") format("woff2"), url("../fonts/ZurichBT-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Zurich Win95BT", sans-serif;
}

h1, h2, h3, h5, h6, label {
  font-family: "zurichlightcondensed";
}

h4 {
  font-weight: bold;
}

h2 {
  font-size: 1.375rem;
  line-height: 2.5rem;
  margin-top: 3.75rem;
  margin-bottom: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.01em;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
}

a {
  color: #719685;
}
a:hover {
  color: #5e7e69;
}
a ion-icon {
  top: 2px;
  position: relative;
  padding-right: 0.25rem;
}

p {
  max-width: 37.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

* {
  outline-color: #5e7e69;
}

.label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}

.info-icon,
.error-icon {
  font-family: "zurichlightcondensed";
  position: relative;
  cursor: pointer;
}
.info-icon span,
.error-icon span {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  min-width: 8.75rem;
  z-index: 200;
  padding: 0.5rem;
  transition: 0.4s ease all;
  line-height: 1.125rem;
  font-size: 0.875rem;
  color: white;
}
.info-icon span::after,
.error-icon span::after {
  content: "";
  position: absolute;
  right: 0;
  transform: translateX(-100%) translateY(-50%);
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.info-icon:hover span,
.error-icon:hover span {
  visibility: visible;
  opacity: 1;
}
.info-icon.left span::after,
.error-icon.left span::after {
  right: 50px;
  transform: translateX(100%) translateY(-50%) rotate(180deg);
  -webkit-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -moz-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -ms-transform: translateX(100%) translateY(-50%) rotate(180deg);
  -o-transform: translateX(100%) translateY(-50%) rotate(180deg);
}

.info-icon span {
  background: #f3960b;
  border: #e9900b 0.0625rem solid;
  left: 3rem;
}
.info-icon span::after {
  border-right: 20px solid #f3960b;
  left: 0;
}
.info-icon.left span {
  left: auto;
  right: 3rem;
}
.info-icon.left span::after {
  left: auto;
  right: 0;
}

.error-icon span {
  background: #F41907;
  border: #ea1807 0.0625rem solid;
  right: 3rem;
}
@media (min-width: 768px) {
  .error-icon span {
    left: 3rem;
  }
}
@media (max-width: 768px) {
  .error-icon span::after {
    transform: translateX(100%) translateY(-50%);
    border-left: 20px solid #F41907;
  }
}
@media (min-width: 768px) {
  .error-icon span::after {
    border-right: 20px solid #F41907;
    left: 0;
  }
}
.error-icon img {
  color: #F41907;
}

.btn-primary {
  padding: 0 1.0625rem;
  height: 2.5rem;
  background-color: #719685;
  font-family: "zurichboldcondensed";
  color: white;
  border: none;
  text-transform: uppercase;
  font-size: 0.8125rem;
  text-decoration: none;
  font-weight: 400;
  transition: 0.2s background-color ease;
  width: 100%;
  letter-spacing: 0.06875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-primary.active {
  background-color: #5e7e69;
}
.btn-primary > img {
  margin-right: 0.5rem;
  height: 1.25rem;
}
@media (min-width: 768px) {
  .btn-primary {
    width: auto;
  }
}
.btn-primary:hover {
  background-color: #658978;
  color: white;
}
.btn-primary.tab-buttons {
  opacity: 0.25;
}
.btn-primary.tab-buttons:hover {
  opacity: 0.5;
}
.btn-primary.tab-buttons.active {
  opacity: 1;
}
.btn-primary:first-of-type {
  margin-right: 0;
}

label img {
  padding: 0 0.5rem 0 0.25rem;
}

select,
input {
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - 3.125rem);
  padding: 0.5rem 0.5rem;
}
@media (min-width: 768px) {
  select,
  input {
    width: auto;
  }
}

select {
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./../icons/select-arrow.svg"); /* add custom arrow */
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  height: 2.5rem;
}

input {
  height: calc(2.5rem - 0.5rem - 0.5rem - 0.25rem);
}

.read-only {
  display: flex;
  position: relative;
  align-items: center;
}
.read-only > input {
  background-color: rgba(0, 0, 0, 0.035);
  border-color: #222222;
  padding-left: 35px;
}
.read-only:before {
  content: url("./../icons/lock.svg");
  position: absolute;
  opacity: 0.7;
  margin-top: 0.125rem;
  left: 0.3125rem;
}

fieldset label,
fieldset .label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}
fieldset label.radio_button,
fieldset .label.radio_button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.625rem;
  padding-right: 1.5rem;
  font-size: 1rem;
}
fieldset label ion-icon,
fieldset .label ion-icon {
  padding: 0 0.375rem;
  color: #f3960b;
}
fieldset select,
fieldset input[type=text],
fieldset input[type=email] {
  min-width: auto;
  width: 100%;
}
@media (min-width: 768px) {
  fieldset select,
  fieldset input[type=text],
  fieldset input[type=email] {
    width: 23.875rem;
  }
}
fieldset textarea {
  width: 23.875rem;
  height: 60px;
  resize: none;
  background: white;
  border: #d9e0d8 solid 0.0625rem;
  border-radius: 0rem;
  padding: 0.5625rem 0.5rem;
}

input,
select {
  font-size: 0.75rem;
  max-width: calc(100% - 0.5rem - 0.5rem);
  padding: 0.5625rem 0.5rem;
}

.input_flex {
  display: flex;
  flex-direction: column;
}

.input_flex_row {
  display: flex;
  flex-direction: row;
}

fieldset {
  width: 100%;
}
@media (min-width: 768px) {
  fieldset {
    width: auto;
  }
}
fieldset div {
  position: relative;
}
fieldset div > div {
  flex-grow: 1;
}
@media (min-width: 768px) {
  fieldset div > div {
    flex-grow: inherit;
  }
}
@media (min-width: 768px) {
  fieldset div {
    width: auto;
  }
}
fieldset div::after {
  position: relative;
  transition: all 0.05s ease-in-out;
  padding-left: 0.25rem;
}
@media (min-width: 768px) {
  fieldset div::after {
    padding-right: 0.5rem;
  }
}
fieldset .concentration {
  padding-left: 0rem;
}
fieldset .concentration::after {
  content: "%";
}
fieldset .effect:after {
  content: "kW";
}
fieldset .pressure:after {
  content: "bar";
}
fieldset .static_height:after,
fieldset .pipe_length::after {
  content: "m";
}
fieldset .additional:after,
fieldset .inlet_temperature:after,
fieldset .return_temperature:after {
  content: "°C";
}
fieldset .volume:after,
fieldset .total_system_volume:after {
  content: "l";
}

.calculate-volume {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 768px) {
  .calculate-volume .input-total-volume {
    flex-grow: 1;
  }
}
.calculate-volume .input-total-volume input {
  width: 100%;
}
.calculate-volume input,
.calculate-volume button {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .calculate-volume input,
  .calculate-volume button {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .calculate-volume .calculate-button-container {
    width: 100%;
  }
}
.calculate-volume .calculate-button-container .calculate-button {
  margin-left: 0.5rem;
}
@media (max-width: 768px) {
  .calculate-volume .calculate-button-container .calculate-button {
    margin-left: 0;
    width: 100%;
  }
}

.fluid-type {
  flex-grow: 1;
}
@media (min-width: 768px) {
  .fluid-type {
    margin-right: 0.375rem;
    flex-grow: 0;
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #eee;
  border-radius: 50%;
  border: solid 0.25rem #eee;
}

/* On mouse-over, add a grey background color */
.container input ~ .checkmark {
  transition: 0.2s ease all;
}
.container:hover input ~ .checkmark {
  background: #719685;
  border: #719685 solid 0.25rem;
}
.container input:focus ~ .checkmark {
  box-shadow: 0 0 2pt 1pt #5e7e69;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: #719685 solid 0.25rem;
}

.container input:focus {
  background-color: blue;
  outline: green 1px solid;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  background: #719685;
  top: 0.4375rem;
  left: 0.4375rem;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50%;
}

.addIconStyle {
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  top: 2px;
}

.iconStyle {
  width: 1rem;
  height: 1rem;
  top: -0.125rem;
  position: relative;
}

.expansion-vessel {
  margin-left: 0;
}
.expansion-vessel.expanded {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .expansion-vessel {
    margin-left: 1.5625rem;
  }
}

.text-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

.number-input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .number-input-container {
    flex-grow: initial;
  }
}

.input-icon-info {
  width: 0.9375rem;
  height: 0.9375rem;
}

.input-icon-error {
  width: 1.5625rem;
  height: 1.5625rem;
}

.invalid-input {
  border-color: #F41907;
  color: #F41907;
}

.select-button-container {
  display: flex;
}
.select-button-container > button {
  margin-right: 0.5rem;
}

.form-wrapper {
  width: 700px;
  margin-right: 20px;
  border: #d9e0d8 solid 0.0625rem;
  position: relative;
  padding-top: 40px;
}
.form-wrapper > a {
  position: absolute;
  width: 350px;
  box-sizing: border-box;
  top: 0;
}
.form-wrapper > a:nth-of-type(1) {
  left: 0;
}
.form-wrapper > a:nth-of-type(2) {
  right: 0;
}

.email-form-wrapper {
  padding: 20px;
}

.send-as-email {
  margin: 0 0 1rem 0;
  display: flex;
  align-items: flex-end;
}
.send-as-email > button {
  margin-left: 0.5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .send-as-email > button {
    margin-left: 3rem;
  }
}

.send-as-inquiry {
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .send-as-inquiry {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 23.875rem;
    max-width: 23.875rem;
  }
}
.send-as-inquiry > button {
  margin-top: 0.5rem;
  width: fit-content;
}

.fluid-container {
  display: flex;
  align-items: flex-end;
}

.number-input-outer-container {
  display: flex;
  align-items: center;
}

.select-with-info-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .select-with-info-container {
    min-width: 200px;
  }
}

input[type=text],
input[type=number],
textarea,
select {
  font-size: 1rem;
}
@media (min-width: 768px) {
  input[type=text],
  input[type=number],
  textarea,
  select {
    font-size: 0.75rem;
  }
}

@font-face {
  font-family: "zurichblack";
  src: url("../fonts/zurichbt-black-webfont.woff2") format("woff2"), url("../fonts/zurichbt-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichboldcondensed";
  src: url("../fonts/zurichbt-boldcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-boldcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichlightcondensed";
  src: url("../fonts/zurichbt-lightcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-lightcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zurich Win95BT";
  src: url("../fonts/ZurichBT-Roman.woff2") format("woff2"), url("../fonts/ZurichBT-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Zurich Win95BT", sans-serif;
}

h1, h2, h3, h5, h6, label {
  font-family: "zurichlightcondensed";
}

h4 {
  font-weight: bold;
}

h2 {
  font-size: 1.375rem;
  line-height: 2.5rem;
  margin-top: 3.75rem;
  margin-bottom: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.01em;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
}

a {
  color: #719685;
}
a:hover {
  color: #5e7e69;
}
a ion-icon {
  top: 2px;
  position: relative;
  padding-right: 0.25rem;
}

p {
  max-width: 37.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

body {
  background: white;
  font-size: 1rem;
  color: #707070;
}

.logotype {
  width: 100%;
  max-width: 12.5rem;
  margin: 0 auto;
  padding-bottom: 1.5rem;
  display: block;
}

.main_wrapper {
  padding: 1.5rem;
}

.breadcrumbs {
  padding-bottom: 1.5rem;
}

.tippy-tooltip.varmber-theme {
  background-color: #f3960b;
  color: white;
}

.tippy-tooltip.varmber-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-tooltip.varmber-theme .tippy-backdrop {
  background-color: #f3960b;
}

.p_row {
  display: flex;
  justify-content: space-between;
}
.p_row > div {
  width: 30%;
}
.p_row > div p {
  text-align: left;
}

@font-face {
  font-family: "zurichblack";
  src: url("../fonts/zurichbt-black-webfont.woff2") format("woff2"), url("../fonts/zurichbt-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichboldcondensed";
  src: url("../fonts/zurichbt-boldcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-boldcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zurichlightcondensed";
  src: url("../fonts/zurichbt-lightcondensed-webfont.woff2") format("woff2"), url("../fonts/zurichbt-lightcondensed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zurich Win95BT";
  src: url("../fonts/ZurichBT-Roman.woff2") format("woff2"), url("../fonts/ZurichBT-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Zurich Win95BT", sans-serif;
}

h1, h2, h3, h5, h6, label {
  font-family: "zurichlightcondensed";
}

h4 {
  font-weight: bold;
}

h2 {
  font-size: 1.375rem;
  line-height: 2.5rem;
  margin-top: 3.75rem;
  margin-bottom: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.01em;
}

h3 {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;
}

a {
  color: #719685;
}
a:hover {
  color: #5e7e69;
}
a ion-icon {
  top: 2px;
  position: relative;
  padding-right: 0.25rem;
}

p {
  max-width: 37.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.table-container {
  border: #d9e0d8 solid 0.0625rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  overflow: auto;
  margin-right: 1rem;
}
.table-container .alternative-button {
  text-align: left;
  background: transparent;
  border: none;
  padding: 0;
}
.table-container .alternative-button:focus {
  outline: none;
  color: #719685;
}
.table-container .alternative-button:focus h3 {
  color: #719685;
}
.table-container .alternative-button h3 {
  margin-bottom: 0;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  border-color: #719685;
  color: #719685;
}
.clickable:hover h3 {
  color: #719685;
}
.clickable button {
  cursor: pointer;
}

.alternative {
  overflow-x: auto;
}

.alternative-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.alternative-table thead {
  background-color: #719685;
  color: #fff;
  font-family: "zurichlightcondensed";
  border-top: 0.75rem solid #719685;
  border-bottom: 0.75rem solid #719685;
}
.alternative-table thead > tr th {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  height: 3rem;
  vertical-align: middle;
}
.alternative-table tbody > tr:last-child {
  border-bottom: none;
}
.alternative-table tbody > tr > td {
  vertical-align: middle;
  padding-left: 1rem;
}
.alternative-table tbody > tr > td p {
  margin: 0;
  padding: 0;
}
.alternative-table .articleNumber {
  white-space: nowrap;
}
.alternative-table .description {
  word-break: break-word;
  max-width: 17.5rem;
  min-width: 13.125rem;
}
.alternative-table img {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 6.25rem;
  height: auto;
  max-height: 12.5rem;
}

.addons {
  margin-top: 3rem;
  margin-left: 9.375rem;
}
.addons h3 {
  margin-bottom: 0;
  font-family: "zurichboldcondensed";
}
.addons h4 {
  margin-bottom: 0.5rem;
}

.report {
  margin: 3rem 0;
}